import t from "./types";
import { Api } from "../../services/httpService";


export const getMerchantList = () => async (dispatch) => {
    const res = await Api('get', `/merchant`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_MERCHANT, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_MERCHANT, payload: res.body });
    }
}

export const Login = (params) => async (dispatch) => {
    const res = await Api('post', '/users/login', params);
    await dispatch({ type: t.LOGIN, payload: res });
}


export const getNotifications = (param) => async (dispatch) => {
    const res = await Api('POST', '/notifications/findAsParamMsg', param);
    if (res.success) {
        dispatch({ type: t.GET_NOTIFICATION_LIST, payload: res });
    } else {
        console.log("error", res);
    }
}


export const getRemittance = (merchantId, buyerPlatformId) => async (dispatch) => {




    try {
        // const res = await Api('get', `/payments/${merchantId}/${buyerPlatformId}`)
        // if (res) {
        //     await dispatch({ type: t.GET_REMITTANCE, payload: res });
        // }

        const requestOptions = {
            method: 'GET',
            headers: {
                "user-agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.84 Safari/537.36",
                "Origin": "https://marketplace.carrefouruae.com",
                "referer": "https://marketplace.carrefouruae.com/",
                "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlF6UXpRekpFTlVNeE16aEdOemcwUlRjeVF6VXdRVEkyTWpoRlJEZEROVE0xTVVNM1F6TTJPUSJ9.eyJodHRwczovL21hZi5ncmF2dHkuYXV0aC9kZXYvYXBpL2VtYWlsIjoibmFqYW1hbHRhakBnbWFpbC5jb20iLCJodHRwczovL21hZi5pZGVudGl0eS5hdXRoL2Rldi9hcGkvZW1haWwiOiJuYWphbWFsdGFqQGdtYWlsLmNvbSIsImh0dHBzOi8vbWFmLmlkZW50aXR5LmF1dGgvZGV2L2FwaS9yaWQiOm51bGwsImh0dHBzOi8vbWFmLmlkZW50aXR5LmF1dGgvZGV2L2FwaS9taXJha2xfc2hvcF9pZCI6IjEwMTIzIiwiaHR0cHM6Ly9tYWYuaWRlbnRpdHkuYXV0aC9kZXYvYXBpL3RpbWUiOiIyMDIyLTA0LTA2VDA4OjA2OjE2LjIwM1oiLCJodHRwczovL21hZi5ncmF2dHkuYXV0aC9kZXYvYXBpL3V1aWQiOiIzMjExMmVkOC1iMzk3LTQ2M2MtYjJkNS0yNDEwNzI4OGFiZjYiLCJodHRwczovL21hZi5pZGVudGl0eS5hdXRoL2Rldi9hcGkvdXVpZCI6IjMyMTEyZWQ4LWIzOTctNDYzYy1iMmQ1LTI0MTA3Mjg4YWJmNiIsImh0dHBzOi8vbWFmLmlkZW50aXR5LmF1dGgvZGV2L2FwaS9lbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaHR0cHM6Ly9tYXJrZXRwbGFjZS5jYXJyZWZvdXIvcm9sZXMiOlsic2VsbGVyLXN0YW5kYXJkIl0sImh0dHBzOi8vbWFya2V0cGxhY2UuY2FycmVmb3VyL2VtYWlsIjoibmFqYW1hbHRhakBnbWFpbC5jb20iLCJodHRwczovL21hcmtldHBsYWNlLmNhcnJlZm91ci9jb3VudHJ5IjoiVUFFIiwiaHR0cHM6Ly9tYXJrZXRwbGFjZS5jYXJyZWZvdXIvc3ViVXNlciI6ZmFsc2UsImlzcyI6Imh0dHBzOi8vaWRlbnRpdHkubWFqaWRhbGZ1dHRhaW0uY29tLyIsInN1YiI6ImF1dGgwfDYxMzBkMTkxYjFkNzc4NWZhZWI5ZmM4YyIsImF1ZCI6WyJodHRwczovL21hcmtldHBsYWNlLmNhcnJlZm91ciIsImh0dHBzOi8vcHJvZHVjdGlvbi5tYWYuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTY0OTIzMjM3NywiZXhwIjoxNjQ5MzE4Nzc3LCJhenAiOiJmaXJKZE81U0tNMEtObGduYWFrYnlqSTdXaXQ2YU5tQiIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJwZXJtaXNzaW9ucyI6W119.lj3olSkmAv5nGOM2rYfIsw3u3Pp6w7nJuSNBvq9giI712-J_uEV8OLVBvHzx0bzxKiBvgsZhvL4cFbI2TL9LKfeKPy0HAxvXiyaq3Ma_yQY3oCd4yOkvnQhBnn44bw1SaTkF0VJ13PDuhuEub7I7PDZ_QoiImmOnEKJCWjiRs6sJXFCYFekAhdzCtxT4qT-K_MOfsZs9xMTK2PR3I2YLDaUc_e7Hs_TbMe609-QwL3STasvcSYov4EyPI64mcquxLpt81LPltgWRZFO6exVLCy-Inl0YWTk3U3DnddJilEumqKqWiQqFK1DCI1QNhXGyc5l7GzYdLJpQtcMhYYCcwQ",

            }
        };
        let response = fetch("https://marketplace-api.carrefouruae.com/api/reports/payments/UAE", requestOptions);
        console.log(response);

    } catch (error) {
        console.log("error", error);
    }
}



