
// export const socketUrl = 'http://127.0.0.1:4001';

//Production

// export const url = "https://java.efunder.ai";
// export const monitorUrl = 'https://app.efunder.ai';
// export const socketUrl = 'https://socket.efunder.ai';


//Staging
//export const url = 'https://javastage.efunder.ai';
//export const monitorUrl = 'https://appstage.efunder.ai';
export const socketUrl = 'https://socketstage.efunder.ai';


// export const monitorUrl = 'http://localhost:3000';


export const url = 'https://dsjava.efunder.ai';
export const monitorUrl = 'https://dsjava.efunder.ai';

