import t from "./types";
import { Api } from "../../services/httpService";


export const getBuyerPlatform = (neer) => async (dispatch) => {

    const res = await Api('get', `/buyerPlatforms`);
    if (res) {
        await dispatch({ type: t.FETCH_BUYER_PLATFORM, payload: res._embedded });
    } else {
        await dispatch({ type: t.FETCH_BUYER_PLATFORM, payload: res._embedded });
    }
}
export const addOrUpdateSubMerchant = (param) => async (dispatch) => {
    const res = await Api("post", '/merchant/submerchant/addorupdate', param);
    if (res) {
        await dispatch({ type: t.ADD_UPDATE_SUB_MERCHANT, payload: res })
    } else {
        console.log("Error in add update submerchant");
    }
}

export const getSubMerchant = (param) => async (dispatch) => {
    const res = await Api("POST", `/merchant/submerchant`, param);
    if (res) {
        await dispatch({ type: t.GET_SUB_MERCHANT, payload: res })
    } else {
        console.log("Error in add update submerchant");
    }
}