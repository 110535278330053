import { url } from "../baseURL";

export const Api = async (reqType, apiRouteUrl, body, isFormData = false) => {
    try {

        let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }

        // console.log(url + apiRouteUrl)
        // console.log(tokenHeader)
        if (isFormData === true) {
            const requestOptions = {
                method: reqType,
                headers: {
                    "Authorization": tokenHeader,
                    "menuUrl": window.localStorage.getItem('menuUrl')
                },
                body: body
            };

            let APIURL = url + apiRouteUrl;

            let response = await fetch(APIURL, requestOptions);
            let data = [];
            if (response.status == 200) {
                data = await response.json();
            } else {
                data = [];
            }
            return data;

        } else {
            const requestOptions = {
                method: reqType,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": tokenHeader,
                    "menuUrl": window.localStorage.getItem('menuUrl')
                },
                body: JSON.stringify(body)
            };

            let APIURL = url + apiRouteUrl;
            let response = await fetch(APIURL, requestOptions);

            let data = [];
            if (response.status == 200) {
                data = await response.json();
            } else {
                data = await response.json();
            }
            return data;
        }

    } catch (err) {
        return { success: err }
    }
}