import t from "../../actions/MerchantActions/types";
const initialState = {
    merchantList: {
        content: []
    },
    notificationList: [],
    loginData: {},
    totalElement: 0,
    remittanceData: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_MERCHANT:
            //console.log("Mechant Action ",action.payload);
            return {
                ...state,
                merchantList: action.payload
            }
            break;
        case t.LOGIN:
            return {
                ...state,
                loginData: action.payload
            }
        case t.GET_NOTIFICATION_LIST:
            return {
                ...state,
                notificationList: action.payload.body?.content,
                totalElement: action.payload.body?.totalElements
            }
        case t.GET_REMITTANCE:

            if (action.payload.body) {
                var sortedData = action.payload.body.payments.sort((a, b) => {
                    return b.paymentsDate - a.paymentsDate;
                });
            }

            return {
                ...state,
                remittanceData: action.payload.body ? sortedData : []
            }
        default:
            return state;
            break;
    }
}
