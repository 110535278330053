import React, { Component } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter, useHistory, useLocation } from 'react-router-dom';
import Private from './routes/private';
import { connect } from 'react-redux';
import { PermissionActions } from './actions';
import history from './history';

const Login = React.lazy(() => import('./pages/Login/login'));




class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      localStorage: window.localStorage.getItem('_token') ? window.localStorage.getItem('_token') : ""
    }
  }

  render() {
    const { childProps } = this.state.localStorage
    return (
      <div>
        <Router history={history}>
          {this.state.localStorage ?
            <Private childProps={childProps} />
            :
            <React.Suspense fallback={<p>loading...</p>}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/admin/login" component={Login} />
              </Switch>
            </React.Suspense>
          }
        </Router>
      </div>

    );
  }
}

const mapStateToProps = ({ PermissionReducers }) => {

  return {
    userRoleMenuList: PermissionReducers.userRoleMenuList,
  };
}

const mapDispatchToProps = {
  getUserRoleMenu: PermissionActions.getUserRoleMenu,
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
