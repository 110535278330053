import t from "../../actions/BuyerPlatFormActions/types";

const initialState = {
    buyerPlatforms: [],
    subMerchant: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCH_BUYER_PLATFORM:
            //console.log("FETCH_BUYER_PLATFORM Reducer ",action.payload);
            return {
                ...state,
                buyerPlatforms: action.payload ? action.payload.buyerPlatforms : []
            }
            break;
        case t.GET_SUB_MERCHANT:
            return {
                ...state,
                subMerchant: action.payload.body,
            };

        default:
            return state;
            break;
    }
}