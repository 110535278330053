import t from "./types";
import { Api } from "../../services/httpService";

// Get all TraditionalRequest List

export const getTraditionalRequest = (status, activePage, size) => async (dispatch) => {
  const res = status ? await Api("get", `/traditionalrequests?status=${status}`) : await Api("get", `/traditionalrequests?page=${activePage}&size=${size}`);
  if (res.success) {
    await dispatch({ type: t.FETCHED_TRADITIONAL_REQUEST, payload: res.body });
  }
};
export const getSearchTraditionalRequest = (requestId) => async (dispatch) => {
  const res = await Api("get", `/traditionalrequests?requestId=${requestId}`)
  if (res.success) {
    await dispatch({ type: t.FETCHED_TRADITIONAL_REQUEST, payload: res.body });
  }
};

// Get single Merchant list
export const getMerchantTraditionalRequest = (merchantId, buyerPlatformId, isInvoice = false) => async (
  dispatch
) => {
  if (isInvoice == true && merchantId) {
    var res = await Api("get", `/traditionalrequests/${merchantId}/${buyerPlatformId}`);
  } else if (isInvoice == true && !merchantId) {
    var res = await Api("get", `/traditionalrequests?buyerPlatformsId=${buyerPlatformId}`);
  } else {
    var res = await Api("get", `/traditionalrequests/${merchantId}`);

  }
  await dispatch({
    type: t.FETCHED_MERCHANT_TRADITIONAL_REQUEST,
    payload: res.body,
  });
};

// Add TraditionalRequest record

export const addTraditionalRequest = (requestParam, traditionalPdf) => async (dispatch) => {
  let merchantBuyerPlatformsId = {};
  merchantBuyerPlatformsId = {
    "merchantId": requestParam.merchantId,
    "buyerPlatformsId": requestParam.buyerPlatformId
  }
  let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId });
  // delete newParam.traditionalPdf;
  //const res = await Api('post', `traditionalrequests/entry?merchantId=${requestParam.merchantId}&userid=${requestParam.merchantId}&buyerPlatformId=${requestParam.buyerPlatformId}&invoices=${requestParam.invoices}&advanceAmount=${requestParam.advanceAmount}&advancePaymentDate=${requestParam.advancePaymentDate}&requestedAmount=${requestParam.requestedAmount}&status=${requestParam.status}&feeAmount=${requestParam.feeAmount}&settlementDate=${requestParam.settlementDate}&proposalAcceptanceDate=${requestParam.proposalAcceptanceDate}`);
  const res = await Api('post', `/traditionalrequests/entry`, newParam);
  if (res.success === true && traditionalPdf) {
    let form_data = new FormData()
    form_data.append('file', traditionalPdf, traditionalPdf.name)
    form_data.append('traditionalRequestsId', res.body.id)
    form_data.append('buyerPlatformsId', newParam.buyerPlatformId)
    form_data.append("merchantId", newParam.merchantId);
    dispatch(traditionalRequestPdf(form_data));
  }

};

// Edit TraditionalRequest record

export const getTraditionalRequestDetails = (traditionalRequestId) => async (dispatch) => {
  const res = await Api("get", `/traditionalrequests/${traditionalRequestId}`);
  if (res.success) {
    await dispatch({
      type: t.FETCHED_TRADITIONAL_REQUEST_DETAILS,
      payload: res.body,
    });
  } else {
    await dispatch({
      type: t.FETCHED_TRADITIONAL_REQUEST_DETAILS,
      payload: res.body,
    });
  }
};

//Update TraditionalRequest Record
export const updateTraditionalRequest = (requestParam, traditionalPdf) => async (dispatch) => {

  let merchantBuyerPlatformsId = {};
  merchantBuyerPlatformsId = {
    "merchantId": requestParam.merchantId,
    "buyerPlatformsId": requestParam.buyerPlatformId
  }
  let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId });
  console.log("newParam:::::::::::", newParam)
  // delete newParam.traditionalPdf;
  //const res = await Api("post",`/wallet/entry?merchantId=${requestParam.merchantId}&transactionType=${requestParam.transactionType}&transactionRemarks=${requestParam.transactionRemarks}&amount=${requestParam.amount}&balance=${requestParam.balance}&id=${requestParam.id}`);
  const res = await Api("post", `/traditionalrequests/entry`, newParam);
  if (res.success === true && traditionalPdf) {
    let form_data = new FormData()
    form_data.append('file', traditionalPdf, traditionalPdf.name)
    form_data.append('traditionalRequestsId', res.body.id)
    form_data.append('buyerPlatformsId', newParam.buyerPlatformId)
    form_data.append("merchantId", newParam.merchantId);
    dispatch(traditionalRequestPdf(form_data));
  }
};

// export const traditionalRequestPdf = (params) => async (dispatch) => {
//   const res = await Api('post', '/traditionalrequests/pdf', params, true);
//   if (res) {
//     await dispatch({ type: t.TRADITIONAL_REQUEST_PDF, payload: res });
//   }
// }
export const traditionalRequestPdf = (params) => async (dispatch) => {
  const res = await Api('post', `/traditionalrequests/pdf`, params, true);
  // if(res){
  //     await dispatch({ type: t.INVOICE_PDF, payload: res }); 
  // }
}

export const setMerchantForSearch = (data) => async (dispatch) => {
  await dispatch({ type: t.SET_MERCHANT_FOR_SEARCH, payload: data })
}
export const resetMerchantForSearch = () => async (dispatch) => {
  await dispatch({ type: t.RESET_MERCHANT_FOR_SEARCH })
}

export const setBuyerplatformForSearch = (data) => async (dispatch) => {
  await dispatch({ type: t.SET_BUYERPLATFORM_FOR_SEARCH, payload: data })
}
export const setRequestForSearch = (data) => async (dispatch) => {
  await dispatch({ type: t.SET_REQUEST_FOR_SEARCH, payload: data })
}
export const setStatusForSearch = (data) => async (dispatch) => {
  await dispatch({ type: t.SET_STATUS_FOR_SEARCH, payload: data })
}

{/* Get Recoveries */ }

export const getRecoveries = (type) => async (dispatch) => {
  try {
    const res = Api('get', "pfpclient/recoveries/findAll");
    if (type === "Request") {
      await dispatch({ type: t.GET_REQUEST_RECOVERIES, payload: res });
    } else {
      await dispatch({ type: t.GET_PENDING_RECOVERIES, payload: res });
    }
  } catch (error) {
    console.log("Error", error);
  }
}






