import t from "./types";
import { Api } from '../../services/httpService';

// Get all wallet List 

export const getPending = () => async (dispatch) => {
    const res = await Api('get', `/pending`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_PENDING, payload: res.body });
    }
}

// Get single Merchant list
export const getPendingWallet = (merchantId, buyerPlatformId, isInvoice = false) => async (dispatch) => {
    if (isInvoice == true && merchantId) {
        var res = await Api('get', `/pending/${merchantId}/${buyerPlatformId}`);
    } else if (isInvoice == true && !merchantId) {
        var res = await Api('get', `/pending?buyerPlatformsId=${buyerPlatformId}`);
    } else {
        var res = await Api('get', `/pending/${merchantId}`);
    };
    await dispatch({ type: t.FETCHED_MERCHANT_PENDING, payload: res.body });

}

// Add Wallet record

export const addPending = (requestParam) => async (dispatch) => {
    let merchantBuyerPlatformsId = {};
    merchantBuyerPlatformsId = {
        "merchantId": requestParam.merchantId,
        "buyerPlatformsId": requestParam.buyerPlatformId
    }
    let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId });
    // const res = await Api('post', `/wallet/entry?merchantId=${requestParam.merchantId}&transactionType=${requestParam.transactionType}&transactionRemarks=${requestParam.transactionRemarks}&amount=${requestParam.amount}&balance=${requestParam.balance}`);
    const res = await Api('post', `/pending/entry`, newParam);
}


// Edit Wallet record 

export const getPendingDetails = (pendingId) => async (dispatch) => {
    const res = await Api('get', `/pending/${pendingId}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_PENDING_DETAILS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_PENDING_DETAILS, payload: res.body });
    }
}

//Update Wallet Record
export const updatePending = (requestParam) => async (dispatch) => {
    let merchantBuyerPlatformsId = {};
    merchantBuyerPlatformsId = {
        "merchantId": requestParam.merchantId,
        "buyerPlatformsId": requestParam.buyerPlatformId
    }
    let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId });
    const res = await Api('post', `/pending/entry`, newParam);
}
