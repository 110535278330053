import t from "../../actions/PendingActions/types";

const initialState = {
  pendingList: {
    content: [],
    pending: []
  },
  merchantPendingList: {
    pending: []
  },
  allPendingList: {},
  pendingDetails: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_PENDING:
      let allPendingList = action.payload
      return {
        ...state,
        pendingList: action.payload,
        allPendingList
      };

    case t.FETCHED_MERCHANT_PENDING:
      let oldPendingList = state.pendingList;

      oldPendingList["content"] = action.payload ? action.payload.pending ? action.payload.pending : action.payload.content : [];
      return {
        ...state,
        pendingList: oldPendingList,
      };

    case t.FETCHED_PENDING_DETAILS:
      //console.log("details", action.payload);
      return {
        ...state,
        pendingDetails: action.payload
      }


    default:
      return state;
  }
};
