import t from "./types";
import { Api } from '../../services/httpService';

// Get all Payment List 

export const getPayment = () => async (dispatch) => {
    const res = await Api('get', `/payments`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_PAYMENT, payload: res.body });
    }
}
export const getSearchPayment = (paymentsNumber) => async (dispatch) => {
    const res = await Api('get', `/payments?paymentsNumber=${paymentsNumber}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_PAYMENT, payload: res.body });
    }
}

// Get single Merchant list
export const getMerchantPayment = (merchantId, buyerPlatformId, isInvoice = false) => async (dispatch) => {
    if (isInvoice == true && merchantId) {
        var res = await Api('get', `/payments/${merchantId}/${buyerPlatformId}`);

    } else if (isInvoice == true && !merchantId) {
        var res = await Api('get', `/payments?buyerPlatformsId=${buyerPlatformId}`);

    } else {
        var res = res = await Api('get', `/payments/${merchantId}`);

    }
    await dispatch({ type: t.FETCHED_MERCHANT_PAYMENT, payload: res.body });
}

// Add Payment record

export const addPayment = (requestParam) => async (dispatch) => {
    let merchantBuyerPlatformsId = {};
    merchantBuyerPlatformsId = {
        "merchantId": requestParam.merchantId,
        "buyerPlatformsId": requestParam.buyerPlatformId
    }
    let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId });
    // const res = await Api('post', `/wallet/entry?merchantId=${requestParam.merchantId}&transactionType=${requestParam.transactionType}&transactionRemarks=${requestParam.transactionRemarks}&amount=${requestParam.amount}&balance=${requestParam.balance}`);
    const res = await Api('post', `/payments/entry`, newParam);
}


// Edit Payment record 

export const getPaymentDetails = (paymentId) => async (dispatch) => {
    const res = await Api('get', `/payments/${paymentId}`);
    if (res.success) {
        dispatch({ type: t.FETCHED_PAYMENT_DETAILS, payload: res.body });
    } else {
        dispatch({ type: t.FETCHED_PAYMENT_DETAILS, payload: res.body });
    }
}


//Update Payment Record
export const updatePayment = (requestParam) => async (dispatch) => {
    let merchantBuyerPlatformsId = {};
    merchantBuyerPlatformsId = {
        "merchantId": requestParam.merchantId,
        "buyerPlatformsId": requestParam.buyerPlatformId
    }

    let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId, "invoices": requestParam.invoices });

    const res = await Api('post', `/payments/entry`, newParam);
}

// get Automation details of settlement for traditionals

export const getAutomationData = (param) => async (dispatch) => {
    try {
        let res = await Api("GET", `/payments/calculateInvoicePayment?invoiceId=${param.invoiceId}&settlementDate=${param.settlementDate}&settlement=${param.receivedAmountByBuyer}`)
        if (res) {
            dispatch({ type: t.AUTOMATION_DATA_FOR_TRADITIONAL, payload: res, param: param });
        }

    } catch (error) {
        console.log("Error ", error);
    }
}