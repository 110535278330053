import t from "../../actions/ClientMonitorActions/types";

const initialState = {
  dashboardList: [],
  dashboardListError: undefined,
  dashboardListLoading: false,
  merchantBuyerReport: [],
  currencyList: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_ALL_DASHBOARD_DATA:

      let traditionalData = [];
      let ecomData = [];
      let workingData = [];
      if (action.payload) {
        traditionalData = action.payload.filter((item) => {
          return item.typeDescription == "Traditional" && item.merchantStatus == action.data
        });
        ecomData = action.payload.filter((item) => {
          return item.typeDescription == "E-Commerce" && item.merchantStatus == action.data
        });
        workingData = action.payload.filter((item) => {
          return item.typeDescription == "Working Capital" && item.merchantStatus == action.data
        })

      }
      return {
        ...state, traditionalData, ecomData, workingData
      };
    case t.FETCHED_ALL_DASHBOARD_DATA_LOADING:
      return {
        ...state, dashboardListLoading: action.payload
      };
    case t.FETCHED_ALL_DASHBOARD_DATA_ERROR:
      return {
        ...state, dashboardListError: action.payload
      };
    case t.FETCHED_MERCHANT_BUYERPLATFORM_REPORT:
      return {
        ...state,
        merchantBuyerReport: action.payload
      }
    case t.GET_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload
      }

    default:
      return state;
  }
}
