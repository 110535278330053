import t from "../../actions/FilterActions/types";

const initialState = {
    filterData: {},
    filterDataResponse: {
        content: []
    },
    pendingDataResponse: {
        content: []
    },
    purchaseDataResponse: {
        content: []
    },
    traditionalDataResponse: [],
    documentLibrary: {
        content: []
    },
    documentLibraryTotalPages: 0,
    walletDataResponse: {
        content: []
    },
    totalPages: 0,
    paymentDataResponse: {
        content: []
    },
    paymentTotalPages: 0,
    exportData: [],
    searchData: {
        "invoices": {},
        "payments": {
            "merchantBuyerPlatformsId": {},
            "paymentType": "Settlement"
        },
        "PurchaseOrder": {},
        "pending": {
            "merchantBuyerPlatformsId": {}
        },
        "wallet": {
            "merchantBuyerPlatformsId": {}
        },
        "traditionalrequests": {
            "merchantBuyerPlatformsId": {}
        }
    }


}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.SET_FILTER_DATA:
            return {
                ...state,
                filterData: action.payload
            }
            break;
        case t.RESET_FILTER_DATA:
            return {
                ...state,
                filterData: {}
            }
            break;
        case t.GET_FILTER_DATA:
            return {
                ...state,
                filterDataResponse: action.payload ? action.payload : state.filterDataResponse,
                totalPages: action.payload.totalPages ? action.payload.totalPages : 0,

                pendingDataResponse: action.payload.pending ? action.payload.pending.content : state.pendingDataResponse,
                pendingTotalPages: action.payload.pending ? action.payload.pending.totalPages : 0,

                purchaseDataResponse: action.payload.purchaseOrder ? action.payload.purchaseOrder.content : state.purchaseDataResponse,
                purchaseTotalPages: action.payload.purchaseOrder ? action.payload.purchaseOrder.totalPages : 0,

                traditionalDataResponse: action.payload.traditionalrequests ? action.payload.traditionalrequests.content : [],
                traditionalTotalPages: action.payload.traditionalrequests ? action.payload.traditionalrequests.totalPages : 0,
            }
            break;
        case t.GET_FILTER_DATA_PAYMENTS:
            return {
                ...state,
                paymentDataResponse: action.payload ? action.payload.payments : state.paymentDataResponse,
                paymentTotalPages: action.payload.totalPages ? action.payload.totalPages : 0,
            }
            break;
        case t.GET_FILTER_DATA_WALLET:
            return {
                ...state,
                walletDataResponse: action.payload ? action.payload : state.filterDataResponse,
                walletTotalPages: action.payload.totalPages ? action.payload.totalPages : 0,

            }
            break;
        case t.GET_EXPORT_DATA:
            return {
                ...state,
                exportData: action.payload.content
            }
            break;

        case t.GET_EXPORT_PAYMENT_DATA:
            return {
                ...state,
                exportData: action.payload.payments.content
            }
            break;
        case t.GET_EXPORT_PURCHASE_ORDER_DATA:
            return {
                ...state,
                exportData: action.payload.purchaseOrder.content
            }
            break;
        case t.GET_EXPORT_PENDING_DATA:
            return {
                ...state,
                exportData: action.payload.pending.content
            }
            break;
        case t.GET_EXPORT_WALLET_DATA:
            return {
                ...state,
                exportData: action.payload.content
            }
            break;
        case t.GET_EXPORT_REQUEST_DATA:
            return {
                ...state,
                exportData: action.payload.traditionalrequests.content
            }
            break;
        case t.GET_DOCUMENT_LIBRARY_DATA:

            return {
                ...state,
                documentLibrary: action.payload ? action.payload.content : state.documentLibrary,
                documentLibraryTotalPages: action.payload ? action.payload.totalPages : 0
            }
            break;
        case t.SET_SEARCH_DATA:

            switch (action.requestFor) {
                case "invoices":
                    if (action.payload.sort) {
                        state.searchData.invoices.sort = action.payload.sort;
                    }

                    if (action.payload.type && action.payload.type != "") {
                        state.searchData.invoices.type = action.payload.type;
                    } else if (action.payload.purchaseOrderNum === "") {
                        delete state.searchData.invoices.purchaseOrderNum
                    }

                    if (action.payload.invoiceStatus && action.payload.invoiceStatus != "") {
                        state.searchData.invoices.invoiceStatus = action.payload.invoiceStatus;
                    } else if (action.payload.invoiceStatus === "") {
                        delete state.searchData.invoices.invoiceStatus
                    }

                    if (action.payload.invoiceNumber && action.payload.invoiceNumber != "") {
                        state.searchData.invoices.invoiceNumber = action.payload.invoiceNumber;
                    } else if (action.payload.invoiceNumber === "") {
                        delete state.searchData.invoices.invoiceNumber
                    }

                    break;

                case "payments":
                    if (action.payload.sort) {
                        state.searchData.payments.sort = action.payload.sort;
                    }

                    if (action.payload.paymentsNumber && action.payload.paymentsNumber != "") {
                        state.searchData.payments.paymentsNumber = action.payload.paymentsNumber;
                    } else if (action.payload.paymentsNumber === "") {
                        delete state.searchData.payments.paymentsNumber
                    }

                    break;

                case "PurchaseOrder":
                    if (action.payload.sort) {
                        state.searchData.PurchaseOrder.sort = action.payload.sort;
                    }
                    if (action.payload.purchaseOrderNum && action.payload.purchaseOrderNum != "") {
                        state.searchData.PurchaseOrder.purchaseOrderNum = action.payload.purchaseOrderNum;
                    } else if (action.payload.purchaseOrderNum === "") {
                        delete state.searchData.PurchaseOrder.purchaseOrderNum
                    }
                    break;

                case "pending":
                    if (action.payload.sort) {
                        state.searchData.pending.sort = action.payload.sort;
                    }
                    if (action.payload.status && action.payload.status != "") {
                        state.searchData.pending.status = action.payload.status;
                    } else if (action.payload.status === "") {
                        delete state.searchData.pending.status;
                    }
                    break;

                case "wallet":
                    if (action.payload.sort) {
                        state.searchData.wallet.sort = action.payload.sort;
                    }
                    break;

                case "traditionalrequests":
                    if (action.payload.sort) {
                        state.searchData.traditionalrequests.sort = action.payload.sort;
                    }
                    if (action.payload.status && action.payload.status != "") {
                        state.searchData.traditionalrequests.status = action.payload.status;
                    } else if (action.payload.status === "") {
                        delete state.searchData.traditionalrequests.status;
                    }
                    if (action.payload.requestId && action.payload.requestId != "") {
                        state.searchData.traditionalrequests.requestId = action.payload.requestId;
                    } else if (action.payload.requestId === "") {
                        delete state.searchData.traditionalrequests.requestId
                    }

                    break;
                default:
                    break;
            }





            // add merchant id 
            if (action.payload.merchantId && action.payload.merchantId != "") {
                state.searchData.wallet.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //wallet
                state.searchData.traditionalrequests.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; // request
                state.searchData.pending.merchantBuyerPlatformsId.merchantId = action.payload.merchantId;// pending
                state.searchData.PurchaseOrder.merchantId = action.payload.merchantId; // PurchaseOrder
                state.searchData.invoices.merchantId = action.payload.merchantId; // invoices
                state.searchData.payments.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //payments
            }

            // delete merchant id

            if (action.payload.merchantId === "") {
                delete state.searchData.wallet.merchantBuyerPlatformsId.merchantId;  // wallet 
                delete state.searchData.traditionalrequests.merchantBuyerPlatformsId.merchantId;  // request
                delete state.searchData.pending.merchantBuyerPlatformsId.merchantId; // pending
                delete state.searchData.PurchaseOrder.merchantId; // PurchaseOrder
                delete state.searchData.invoices.merchantId; // invioces
                delete state.searchData.payments.merchantBuyerPlatformsId.merchantId;  // payments 


            }

            // add buyer platform

            if (action.payload.buyerPlatformsId && action.payload.buyerPlatformsId != "") {
                state.searchData.wallet.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId; // wallet 
                state.searchData.traditionalrequests.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId; //request
                state.searchData.pending.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;// pending
                state.searchData.PurchaseOrder.buyerPlatformsId = action.payload.buyerPlatformsId;// PurchaseOrder
                state.searchData.invoices.buyerPlatformId = action.payload.buyerPlatformsId; // invoices
                state.searchData.payments.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;  // payments
            }

            // delete buyerplatform 
            if (action.payload.buyerPlatformsId === "") {
                delete state.searchData.wallet.merchantBuyerPlatformsId.buyerPlatformsId; // wallet
                delete state.searchData.traditionalrequests.merchantBuyerPlatformsId.buyerPlatformsId; //request
                delete state.searchData.pending.merchantBuyerPlatformsId.buyerPlatformsId; // pending
                delete state.searchData.PurchaseOrder.buyerPlatformsId; // pending
                delete state.searchData.invoices.buyerPlatformId; // invoices
                delete state.searchData.payments.merchantBuyerPlatformsId.buyerPlatformsId;  // payments
            }

            return {
                ...state,
                searchParam: state.searchData
            }
            break
        default:
            return state;
            break;
    }
}