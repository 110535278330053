export default {
    SET_FILTER_DATA: 'SET_FILTER_DATA',
    RESET_FILTER_DATA: 'RESET_FILTER_DATA',
    GET_FILTER_DATA: 'GET_FILTER_DATA',
    GET_FILTER_DATA_PAYMENTS: "GET_FILTER_DATA_PAYMENTS",
    GET_FILTER_DATA_WALLET: "GET_FILTER_DATA_WALLET",
    GET_EXPORT_DATA: "GET_EXPORT_DATA",
    GET_EXPORT_PAYMENT_DATA: "GET_EXPORT_PAYMENT_DATA",
    GET_EXPORT_PURCHASE_ORDER_DATA: "GET_EXPORT_PURCHASE_ORDER_DATA",
    GET_EXPORT_PENDING_DATA: "GET_EXPORT_PENDING_DATA",
    GET_EXPORT_WALLET_DATA: "GET_EXPORT_WALLET_DATA",
    GET_EXPORT_REQUEST_DATA: "GET_EXPORT_REQUEST_DATA",
    GET_DOCUMENT_LIBRARY_DATA: "GET_DOCUMENT_LIBRARY_DATA",
    SET_SEARCH_DATA: "SET_SEARCH_DATA"
}