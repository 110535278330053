import React, { useState } from 'react';
import './Header.css';
import Notifications from '../../Model/Notifications'


//import {FunderLogo} from '../assests/FunderLogo.jpg'; 

const Header = () => {
    const logo = require('../../../assests/Funder.ai.png');
    const bell = require('../../../assests/bell.png');
    const circleWithName = require('../../../assests/Eclipse.png');

    const [notificationsModal, setNotificationsModal] = useState(false)
    const closeModal = (data) => {
        setNotificationsModal(data)
    }
    return (
        <div>
            <div id="header">
                <div className="row align-items-center">
                    <div className="col-6 pl-0">
                        <div className="top_left_title"><img src={logo} /></div>
                    </div>
                    <div className="col-6 text-right">
                        <div className="bell cursor">
                            <img src={bell} width={17} height={17} onClick={() => {
                                setNotificationsModal(true)
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            {notificationsModal && (
                <Notifications
                    openModal={notificationsModal}
                    closeModal={closeModal}
                    data={[]}
                />
            )}
        </div>
    );
}

export default Header;