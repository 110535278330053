import t from "../../actions/DocumentMonitoringAction/types";

const initialState = {
    documentDetails: {},
    addEditDocResponse: {},
    documentViewFile: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_DOCUMENT_MONITORING_DETAILS:
            return {
                ...state,
                documentDetails: action.payload
            }
            break;
        case t.ADD_EDIT_DOCUMENT_MONITORING:
            return {
                ...state,
                addEditDocResponse: action.payload
            }
            break;
        case t.FETCHED_DOCUMENT_MONITORING_FILE:
            return {
                ...state,
                documentViewFile: action.payload
            }
            break;
        default:
            return state;
            break;
    }
}