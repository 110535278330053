import React, { Component } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSignOutAlt, faHome, faDatabase, faComment, faWallet, faUsers, faReceipt, faClock, faIndustry, faShoppingCart, faBell, faFile } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { PermissionActions } from '../../../actions';


export class SideBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUrl: window.location.pathname
        }
    }

    componentDidMount() {
        this.props.getMenuList();
        this.props.getUserRoleMenu(window.localStorage.getItem('_role')).then(() => {
        });
    }


    getFaIcon = (icon) => {
        switch (icon) {
            case "faEye":
                return faEye;
                break;
            case "faHome":
                return faHome;
                break;
            case "faDatabase":
                return faDatabase;
                break;
            case "faComment":
                return faComment;
                break;
            case "faWallet":
                return faWallet;
                break;
            case "faUsers":
                return faUsers;
                break;
            case "faReceipt":
                return faReceipt;
                break;
            case "faClock":
                return faClock;
                break;
            case "faIndustry":
                return faIndustry;
                break;
            case "faShoppingCart":
                return faShoppingCart;
                break;
            case "faBell":
                return faBell;
                break;
            case "faFile":
                return faFile;
                break;
            default:
                break;
        }
    }

    checkRoleMenu = (id) => {
        let roleMenuData = this.props.userRoleMenuList.find((item) => item.menuId == id);
        if (roleMenuData) {
            return true;
        } else {
            return false;
        }
    }

    checkCurrentRoute = (url) => {
        this.setState({
            currentUrl: url
        });
    }

    logout = () => {
        localStorage.clear();
        window.location = '/';

    }



    render() {
        const { menuList } = this.props;
        return (
            <div className="sidebar">
                {menuList.map((menu) => {
                    return (
                        <>
                            {menu.parentId === null ?
                                <>
                                    {menu.children.length > 0 ? <h4 className="sidebar-title">{menu.title}</h4> : ""}

                                    {menu.children.length > 0 && menu.children.map((child, index) => {
                                        return (
                                            <div key={index}>
                                                {this.checkRoleMenu(child.id) ?
                                                    <Link key={index} to={child.menuUrl} onClick={() => this.checkCurrentRoute(child.menuUrl)} className={this.state.currentUrl === child.menuUrl ? "active_link" : ""} >
                                                        <div className="sidebar_item_container" >
                                                            <div className="icon">
                                                                <FontAwesomeIcon icon={this.getFaIcon(child.icon)} />
                                                            </div>
                                                            <p>{child.title}</p>
                                                        </div>
                                                    </Link>
                                                    : ""
                                                }
                                            </div >
                                        )
                                    })
                                    }

                                </>
                                : ""
                            }
                        </>
                    )
                })
                }

                {/* <Link exact to="/admin/requests">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faComment} />
                        </div>
                        <p>Requests</p>
                    </div>
                </Link>
                <Link exact to="/admin/wallet">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faWallet} />
                        </div>
                        <p>Reserve Balance</p>
                    </div>
                </Link>
                <Link exact to="/admin/pending">
                    <div className="sidebar_item_container" >
                        <div className="icon" style={{ top: 16 }} >
                            <FontAwesomeIcon icon={faClock} />
                        </div>
                        <p>Pending</p>
                    </div>
                </Link>
                <h4 className="sidebar-title">Vendor Advance</h4>
                <Link exact to="/admin/purchase">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faShoppingCart} />
                        </div>
                        <p>Purchase Orders</p>
                    </div>
                </Link>
                <Link exact to="/admin/invoices">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faReceipt} />
                        </div>
                        <p>Invoices</p>
                    </div>
                </Link>

                <Link exact to="/admin/trade-payments">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faWallet} />
                        </div>
                        <p>Payments</p>
                    </div>
                </Link>
                <h4 className="sidebar-title">Settings</h4>
                <Link to="/admin/buyerplatforms">
                    <div className="sidebar_item_container" >
                        <div className="icon">
                            <FontAwesomeIcon icon={faDatabase} />
                        </div>
                        <p>Buyer Platforms</p>
                    </div>
                </Link>

                <Link exact to="/admin/merchants">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faUsers} />
                        </div>
                        <p>Merchants</p>
                    </div>
                </Link>
                <Link exact to="/admin/investor">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faUsers} />
                        </div>
                        <p>Investors</p>
                    </div>
                </Link>
                <Link exact to="/admin/traditional">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faIndustry} />
                        </div>
                        <p>Factoring Params</p>
                    </div>
                </Link>
                <Link exact to="/admin/client-monitoring">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faEye} />
                        </div>
                        <p>Account Monitoring</p>
                    </div>
                </Link>
                <Link exact to="/admin/document-monitoring">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faFile} />
                        </div>
                        <p>Document Monitoring</p>
                    </div>
                </Link>
                <Link exact to="/admin/notification-template">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faBell} />
                        </div>
                        <p>Notifications</p>
                    </div>
                </Link>
                <Link exact to="/admin/email-template">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faBell} />
                        </div>
                        <p>Email Templates</p>
                    </div>
                </Link>
                <Link exact to="/admin/cms-page">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faReceipt} />
                        </div>
                        <p>Cms Pages</p>
                    </div>
                </Link>
                <Link exact to="/admin/permission">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faReceipt} />
                        </div>
                        <p>Permission</p>
                    </div>
                </Link> */}
                <Link to="/admin/remittances">
                    <div className="sidebar_item_container" >
                        <div className="icon" >
                            <FontAwesomeIcon icon={faComment} />
                        </div>
                        <p>Remittances</p>
                    </div>
                </Link>
                <Link to="" onClick={() => this.logout()}>
                    <div className="sidebar_item_container" >
                        <div className="icon">
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </div>
                        <p>Logout</p>
                    </div>
                </Link>
                <p className="footer">
                    (c) Protected by Law. Sanctioned under Companies Act. Sanctioned under Companies Act.
                </p>
            </div >
        )
    }
}

const mapStateToProps = ({ PermissionReducers }) => {

    return {
        menuList: PermissionReducers.menuList,
        userRoleMenuList: PermissionReducers.userRoleMenuList,
    }
}

const mapDispatchToProps = {
    getUserRoleMenu: PermissionActions.getUserRoleMenu,
    getMenuList: PermissionActions.getMenuList
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)