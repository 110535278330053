import t from "../../actions/PaymentActions/types";

const initialState = {
  paymentList: {
    content: [],
    payment: []
  },
  merchantWalletList: {
    payment: []
  },
  // allPaymentList: {},
  allPaymentList: {
    content: [],
    payment: []
  },
  paymentDetails: {},
  automationDataForTraditional: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_PAYMENT:
      // let allPaymentList = action.payload
      return {
        ...state,
        paymentList: Object.assign({}, action.payload),
        allPaymentList: Object.assign({}, action.payload),
      };

    case t.FETCHED_MERCHANT_PAYMENT:
      let oldPaymentList = state.paymentList;
      oldPaymentList["content"] = action.payload ? action.payload.payments ? action.payload.payments : action.payload.content : [];
      return {
        ...state,
        paymentList: oldPaymentList,
      };

    case t.FETCHED_PAYMENT_DETAILS:
      state.paymentList["content"] = [action.payload.payments];
      return {
        ...state,
        paymentDetails: action.payload
      }

    case t.AUTOMATION_DATA_FOR_TRADITIONAL:
      return {
        ...state,
        automationDataForTraditional: action
      }


    default:
      return state;
  }
};
