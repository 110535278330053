import t from "./types";
import { Api } from "../../services/httpService";

/* GET ALL NOTIFICATION TEMPLETE LIST */

export const getNotificationTemplates = () => async (dispatch) => {
    dispatch({ type: t.FETCHED_NOTIFICATION_TEMPLETE_LOADING, payload: true });
    const res = await Api("get", `/notifications/getTemplates`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_NOTIFICATION_TEMPLETE, payload: res.body.content ? res.body.content : []  });
    } else {
        await dispatch({ type: t.FETCHED_NOTIFICATION_TEMPLETE_ERROR, payload: res });
    };
    dispatch({ type: t.FETCHED_NOTIFICATION_TEMPLETE_LOADING, payload: false });
};

/* UPDATE NOTIFICATION TEMPLATE */

export const notificationTempleteEntry = (params) => async (dispatch) => {
    dispatch({ type: t.NOTIFICATION_TEMPLETE_ENTRY_LOADING, payload: true });
    const res = await Api("post", `/notifications/templateEntry`, params);
    if (res.success) {
        await dispatch({ type: t.NOTIFICATION_TEMPLETE_ENTRY, payload: res.body });
    } else {
        await dispatch({ type: t.NOTIFICATION_TEMPLETE_ENTRY_ERROR, payload: res });
    };
    dispatch({ type: t.FETCHED_NOTIFICATION_TEMPLETE_LOADING, payload: false });
};
