import t from "../../actions/TraditionalRequestActions/types";

const initialState = {
  requestList: {
    content: [],
    request: [],
  },
  merchantRequestList: {
    request: []
  },
  allRequestList: {
    content: [],
    request: []
  },
  requestDetails: {},
  totalPages: null,
  size: null,
  numberOfElements: null,
  merchantForSearch: {},
  buyerPlatformForSearch: null,
  requestForSearch: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_TRADITIONAL_REQUEST:
      let updatedList = [];
      updatedList["content"] = action.payload && action.payload.content ? action.payload.content : action.payload

      return {
        ...state,
        requestList: Object.assign({}, updatedList),
        allRequestList: Object.assign({}, updatedList),
        totalPages: action.payload && action.payload.totalPages,
        size: action.payload && action.payload.size,
        numberOfElements: action.payload && action.payload.numberOfElements
      };

    case t.FETCHED_MERCHANT_TRADITIONAL_REQUEST:
      let oldRequestList = state.requestList;
      oldRequestList["content"] = action.payload ? action.payload.traditionalrequests ? action.payload.traditionalrequests : action.payload : [];
      return {
        ...state,
        requestList: oldRequestList,
      };

    case t.FETCHED_TRADITIONAL_REQUEST_DETAILS:
      //console.log("FETCHED_TRADITIONAL_REQUEST_DETAILS", action.payload);
      state.requestList["content"] = [action.payload.traditionalrequests];
      return {
        ...state,
        requestDetails: action.payload.traditionalrequests
      }

    case t.SET_MERCHANT_FOR_SEARCH:
      return {
        ...state,
        merchantForSearch: action.payload
      }
    case t.RESET_MERCHANT_FOR_SEARCH:
      return {
        ...state,
        merchantForSearch: {},
        buyerPlatformForSearch: '',
        requestForSearch: ''
      }
    case t.SET_BUYERPLATFORM_FOR_SEARCH:
      return {
        ...state,
        buyerPlatformForSearch: action.payload
      }
    case t.SET_REQUEST_FOR_SEARCH:
      return {
        ...state,
        requestForSearch: action.payload
      }

    default:
      return state;
  }
};
