import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from '../component/layout/Layout';
import { PermissionActions } from '../actions';

const Dashboard = React.lazy(() => import('../component/DashboardComponent/DashBoard'));
const BuyerPlatform = React.lazy(() => import('../component/BuyerPlatformComponent/BuyerPlatform'));
const AddBuyerPlatform = React.lazy(() => import('../component/BuyerPlatformComponent/AddBuyerPlatform/AddBuyerPlatform'));
const View_Edit_BuyerPlatform = React.lazy(() => import('../component/BuyerPlatformComponent/View_Edit_BuyerPlatform/View_Edit_BuyerPlatform'));
const Request = React.lazy(() => import('../component/RequestComponent/Request'));
const Merchants = React.lazy(() => import('../component/MerchantComponent/Merchant'));
const ViewMerchant = React.lazy(() => import('../component/MerchantComponent/ViewMerchant/ViewMerchant'));
const CompanyDetails = React.lazy(() => import('../pages/CompanyDetails/CompanyDetails'));
const BankDetails = React.lazy(() => import('../pages/BankDetails/BankDetails'));
const VirtualBankDetails = React.lazy(() => import('../pages/VirtualBankDetails/VirtualBankDetails'));
const CreateAccountScreen = React.lazy(() => import('../pages/CreateAccountScreen/CreateAccountScreen'));
const TermsScreen = React.lazy(() => import('../pages/TermsScreen/TermsScreen'));
const EditAccount = React.lazy(() => import('../pages/EditAccount/EditAccount'));
const Document = React.lazy(() => import('../pages/Document/Document'));
const BuyerPlatformSetup = React.lazy(() => import('../pages/BuyerPlatformSetup/BuyerPlatformSetup'));
const Payment = React.lazy(() => import('../component/PaymentComponent/Payment'));
const Invoices = React.lazy(() => import('../pages/Invoices/invoices'));
const AddEditInvoice = React.lazy(() => import('../pages/Invoices/addEditInvoice'));
const InvoicesView = React.lazy(() => import('../pages/Invoices/invoicesview'));
const Pending = React.lazy(() => import('../pages/Pending/pending'));
const AddEditPending = React.lazy(() => import('../pages/Pending/addEditPending'));
const PendingView = React.lazy(() => import('../pages/Pending/pendingview'));
const Wallet = React.lazy(() => import('../pages/Wallet/wallet'));
const EditWallet = React.lazy(() => import('../pages/Wallet/editWallet'));
const AddWallet = React.lazy(() => import('../pages/Wallet/addWallet'));
const WalletView = React.lazy(() => import('../pages/Wallet/walletview'));
const Traditional = React.lazy(() => import('../pages/FactParam/traditional'));
const EditTraditional = React.lazy(() => import('../pages/FactParam/editTraditional'));
const TraditionalView = React.lazy(() => import('../pages/FactParam/traditionalview'));
const Purchase = React.lazy(() => import('../pages/Purchase/purchase'));
const PoDetails = React.lazy(() => import('../pages/Purchase/po-details'));
const PurchaseView = React.lazy(() => import('../pages/Purchase/purchaseview'));
const AddEditPurchase = React.lazy(() => import('../pages/Purchase/addEditPurchase'));
const Requests = React.lazy(() => import('../pages/Requests/requests'));
const AddEditRequests = React.lazy(() => import('../pages/Requests/addEditRequests'));
const RequestsView = React.lazy(() => import('../pages/Requests/requestsview'));
const Tradepayments = React.lazy(() => import('../pages/TradePayments/tradepayments'));
const addEditTradePayments = React.lazy(() => import('../pages/TradePayments/addEditTradepayments'));
const TradePaymentsView = React.lazy(() => import('../pages/TradePayments/tradepaymentsview'));
const NotificationTemplate = React.lazy(() => import('../pages/Notifications/notificationtemplates'));
const UpdateNotificationTemplate = React.lazy(() => import('../pages/Notifications/updatenotificationtemplates'));
const NotificationView = React.lazy(() => import('../pages/Notifications/notificationView'));
const EmailTemplate = React.lazy(() => import('../pages/EmailTemplate/emailtemplates'));
const UpdateEmailTemplate = React.lazy(() => import('../pages/EmailTemplate/updateEmailtemplates'));
const TemplateView = React.lazy(() => import('../pages/EmailTemplate/templateView'));
const CmsPages = React.lazy(() => import('../pages/cmsPages/cmspages'));
const UpdateCmsPage = React.lazy(() => import('../pages/cmsPages/updateCmsPage'));
const CmsPageView = React.lazy(() => import('../pages/cmsPages/cmsPagesView'));
const AccountMonitoring = React.lazy(() => import('../pages/AccountMonitor/Monitoring'));
const DocumentMonitoring = React.lazy(() => import('../pages/DocumentMonitoring/DocumentMonitoring'));
const addEditDocumentLibrary = React.lazy(() => import('../pages/DocumentMonitoring/addEditDocumentLibrary'));
const InvestorProfile = React.lazy(() => import('../pages/InvestorProfile/InvestorProfile'));
const Permission = React.lazy(() => import('../pages/Permission/Permission'));
const AddEditPermission = React.lazy(() => import('../pages/Permission/AddEditPermission'));
const UsersRole = React.lazy(() => import('../pages/Permission/UsersRole'));
const CreateUser = React.lazy(() => import('../pages/Permission/CreateUser'));
const EditUserRole = React.lazy(() => import('../pages/Permission/EditUserRole'));
const Remittances = React.lazy(() => import('../pages/Remittances/Remittances'));



class Private extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getUserRoleMenu(window.localStorage.getItem("_role"))
    }




    getUserPermission = (url) => {
        if (this.props.userRoleMenuList) {
            // Add date for permissions
            let roleMenuData = this.props.userRoleMenuList.find((item) => item.menu.menuUrl == url);
            if (roleMenuData) {
                localStorage.setItem("menuUrl", url);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }

    }




    render() {
        return (
            <div className="App" >

                <Layout />
                <div className="body-container" >
                    <React.Suspense fallback={<p>loading...</p>}>
                        <Switch>
                            {this.getUserPermission("/") ? <Route exact path="/" component={Dashboard} /> : ""}

                            {this.getUserPermission("/admin/buyerplatforms") ? <Route exact path="/admin/buyerplatforms" component={BuyerPlatform} /> : ""}
                            {this.getUserPermission("/admin/addBuyerPlatform") ? <Route exact path="/admin/addBuyerPlatform" component={AddBuyerPlatform} /> : ""}
                            {this.getUserPermission("/admin/buyerplatforms/:id") ? <Route exact path="/admin/buyerplatforms/:id" component={View_Edit_BuyerPlatform} /> : ""}

                            {this.getUserPermission("/admin/merchants") ? <Route exact path="/admin/merchants" component={Merchants} /> : ""}
                            {this.getUserPermission("/admin/merchant/:id") ? <Route exact path="/admin/merchant/:id" component={ViewMerchant} /> : ""}
                            {this.getUserPermission("/admin/merchant/companyDetail/:id") ? <Route exact path="/admin/merchant/companyDetail/:id" component={CompanyDetails} /> : ""}
                            {this.getUserPermission("/admin/merchant/bankDetail/:id") ? <Route exact path="/admin/merchant/bankDetail/:id" component={BankDetails} /> : ""}
                            {this.getUserPermission("/admin/merchant/virtualBankDetail/:id") ? <Route exact path="/admin/merchant/virtualBankDetail/:id" component={VirtualBankDetails} /> : ""}
                            {this.getUserPermission("/admin/addMerchant") ? <Route exact path="/admin/addMerchant" component={CreateAccountScreen} /> : ""}
                            {this.getUserPermission("/admin/merchant/account/:id") ? <Route exact path="/admin/merchant/account/:id" component={EditAccount} /> : ""}
                            {this.getUserPermission("/admin/addMerchant/tnc/:id/:name/:phone/:email/:pass") ? <Route exact path="/admin/addMerchant/tnc/:id/:name/:phone/:email/:pass" component={TermsScreen} /> : ""}
                            {this.getUserPermission("/admin/merchant/documents/:id") ? <Route exact path="/admin/merchant/documents/:id" component={Document} /> : ""}
                            {this.getUserPermission("/admin/merchant/setUpBuyerPlatform/:userId/:selectedBP") ? <Route exact path="/admin/merchant/setUpBuyerPlatform/:userId/:selectedBP" component={BuyerPlatformSetup} /> : ""}

                            {this.getUserPermission("/admin/payments") ? <Route exact path="/admin/payments" component={Payment} /> : ""}

                            {this.getUserPermission("/admin/invoices") ? <Route exact path="/admin/invoices" component={Invoices} /> : ""}
                            {this.getUserPermission("/admin/invoices-view/:id") ? <Route exact path="/admin/invoices-view/:id" component={InvoicesView} /> : ""}
                            {this.getUserPermission("/admin/add-invoice") ? <Route exact path="/admin/add-invoice" component={AddEditInvoice} /> : ""}
                            {this.getUserPermission("/admin/edit-invoice/:id") ? <Route exact path="/admin/edit-invoice/:id" component={AddEditInvoice} /> : ""}

                            {this.getUserPermission("/admin/pending") ? <Route exact path="/admin/pending" component={Pending} /> : ""}
                            {this.getUserPermission("/admin/pending-view/:id") ? <Route exact path="/admin/pending-view/:id" component={PendingView} /> : ""}
                            {this.getUserPermission("/admin/add-pending") ? <Route exact path="/admin/add-pending" component={AddEditPending} /> : ""}
                            {this.getUserPermission("/admin/edit-pending/:id") ? <Route exact path="/admin/edit-pending/:id" component={AddEditPending} /> : ""}

                            {this.getUserPermission("/admin/wallet") ? <Route exact path="/admin/wallet" component={Wallet} /> : ""}
                            {this.getUserPermission("/admin/wallet-view/:id") ? <Route exact path="/admin/wallet-view/:id" component={WalletView} /> : ""}
                            {this.getUserPermission("/admin/add-wallet") ? <Route exact path="/admin/add-wallet" component={AddWallet} /> : ""}
                            {this.getUserPermission("/admin/edit-wallet/:id") ? <Route exact path="/admin/edit-wallet/:id" component={EditWallet} /> : ""}

                            {this.getUserPermission("/admin/traditional") ? <Route exact path="/admin/traditional" component={Traditional} /> : ""}
                            {this.getUserPermission("/admin/add-traditional") ? <Route exact path="/admin/add-traditional" component={EditTraditional} /> : ""}
                            {this.getUserPermission("/admin/edit-traditional/:id") ? <Route exact path="/admin/edit-traditional/:id" component={EditTraditional} /> : ""}
                            {this.getUserPermission("/admin/traditional-view/:id") ? <Route exact path="/admin/traditional-view/:id" component={TraditionalView} /> : ""}
                            {this.getUserPermission("/a/admin/requesdmin/po-details") ? <Route exact path="/a/admin/requesdmin/po-details" component={PoDetails} /> : ""}
                            {this.getUserPermission("/admin/purchase") ? <Route exact path="/admin/purchase" component={Purchase} /> : ""}
                            {this.getUserPermission("/admin/edit-purchase/:id") ? <Route exact path="/admin/edit-purchase/:id" component={AddEditPurchase} /> : ""}
                            {this.getUserPermission("/admin/add-purchase") ? <Route exact path="/admin/add-purchase" component={AddEditPurchase} /> : ""}
                            {this.getUserPermission("/admin/purchase-view/:id") ? <Route exact path="/admin/purchase-view/:id" component={PurchaseView} /> : ""}



                            {this.getUserPermission("/admin/add-requests") ? <Route exact path="/admin/add-requests" component={AddEditRequests} /> : ""}
                            {this.getUserPermission("/admin/edit-requests/:id") ? <Route exact path="/admin/edit-requests/:id" component={AddEditRequests} /> : ""}
                            {this.getUserPermission("/admin/requests-view/:id") ? <Route exact path="/admin/requests-view/:id" component={RequestsView} /> : ""}
                            {this.getUserPermission("/admin/trade-payments") ? <Route exact path="/admin/trade-payments" component={Tradepayments} /> : ""}
                            {this.getUserPermission("/admin/add-tradepayments") ? <Route exact path="/admin/add-tradepayments" component={addEditTradePayments} /> : ""}
                            {this.getUserPermission("/admin/edit-tradepayments/:id") ? <Route exact path="/admin/edit-tradepayments/:id" component={addEditTradePayments} /> : ""}
                            {this.getUserPermission("/admin/tradepayments-view/:id") ? <Route exact path="/admin/tradepayments-view/:id" component={TradePaymentsView} /> : ""}
                            {this.getUserPermission("/admin/notification-template") ? <Route exact path="/admin/notification-template" component={NotificationTemplate} /> : ""}
                            {this.getUserPermission("/admin/edit-notification-template/:id") ? <Route exact path="/admin/edit-notification-template/:id" component={UpdateNotificationTemplate} /> : ""}
                            {this.getUserPermission("/admin/view-notification-template/:id") ? <Route exact path="/admin/view-notification-template/:id" component={NotificationView} /> : ""}
                            {this.getUserPermission("/admin/email-template") ? <Route exact path="/admin/email-template" component={EmailTemplate} /> : ""}
                            {this.getUserPermission("/admin/edit-email-template/:id") ? <Route exact path="/admin/edit-email-template/:id" component={UpdateEmailTemplate} /> : ""}
                            {this.getUserPermission("/admin/view-email-template/:id") ? <Route exact path="/admin/view-email-template/:id" component={TemplateView} /> : ""}
                            {this.getUserPermission("/admin/cms-page") ? <Route exact path="/admin/cms-page" component={CmsPages} /> : ""}
                            {this.getUserPermission("/admin/edit-cms-page/:id") ? <Route exact path="/admin/edit-cms-page/:id" component={UpdateCmsPage} /> : ""}
                            {this.getUserPermission("/admin/view-cms-page/:id") ? <Route exact path="/admin/view-cms-page/:id" component={CmsPageView} /> : ""}

                            {this.getUserPermission("/admin/client-monitoring") ? <Route exact path="/admin/client-monitoring" component={AccountMonitoring} /> : ""}
                            {this.getUserPermission("/admin/document-monitoring") ? <Route exact path="/admin/document-monitoring" component={DocumentMonitoring} /> : ""}
                            {this.getUserPermission("/admin/edit-document-monitoring/:id") ? <Route exact path="/admin/edit-document-monitoring/:id" component={addEditDocumentLibrary} /> : ""}
                            {this.getUserPermission("/admin/add-document-monitoring/") ? <Route exact path="/admin/add-document-monitoring" component={addEditDocumentLibrary} /> : ""}
                            {this.getUserPermission("/admin/investor") ? <Route exact path="/admin/investor" component={InvestorProfile} /> : ""}
                            {this.getUserPermission("/admin/permission") ? <Route exact path="/admin/permission" component={Permission} /> : ""}
                            {this.getUserPermission("/admin/permission/users-role/:id") ? <Route exact path="/admin/permission/users-role/:id" component={UsersRole} /> : ""}
                            {this.getUserPermission("/admin/permission/:id") ? <Route exact path="/admin/permission/:id" component={AddEditPermission} /> : ""}
                            {this.getUserPermission("/admin/create/user") ? <Route exact path="/admin/create/user" component={CreateUser} /> : ""}
                            {this.getUserPermission("/admin/edit/user/role/:userId/:roleId") ? <Route exact path="/admin/edit/user/role/:userId/:roleId" component={EditUserRole} /> : ""}

                            {this.getUserPermission("/admin/requests") ? <Route exact path="/admin/requests" component={Requests} /> : ""}
                            <Route exact path="/admin/remittances" component={Remittances} />
                        </Switch>
                    </React.Suspense>
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ PermissionReducers }) => {

    return {
        userRoleMenuList: PermissionReducers.userRoleMenuList,
    };
}

const mapDispatchToProps = {
    getUserRoleMenu: PermissionActions.getUserRoleMenu,
}
export default connect(mapStateToProps, mapDispatchToProps)(Private)