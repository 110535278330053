import { combineReducers } from "redux";
import WalletListReducer from "./WalletReducer/reducer";
import MerchantListReducer from "./MerchantReducers/reducer";
import TraditionalFactoryReducer from "./TaditionalFactoryReducers/reducer";
import BuyerPlatFormReducers from "./BuyerPlatFormReducers/reducer";
import InvoicesReducers from "./InvoicesReducers/reducer";
import PaymentReducers from "./PaymentReducers/reducer";
import PendingReducers from "./PendingReducers/reducer";
import PurchaseOrdersReducers from "./PurchaseOrdersReducers/reducer";
import TraditionalRequestReducers from "./TraditionalRequestReducers/reducer";
import NotificationReducer from './NotificationReducer/reducer';
import EmailTemplateReducer from './EmailTemplateReducer/reducer';
import CmsReducer from './CmsReducer/reducer';
import ClientMonitorReducer from './ClientMonitorReducer/reducer';
import FilterReducer from './FilterReducers/reducer';
import RecoveriesReducers from "./RecoveriesReducers/reducer";
import DocumentMonitoringReducers from "./DocumentMonitoringReducers/reducer";
import PermissionReducers from "./PermissionReducers/reducer";



const rootReducers = combineReducers({
    WalletListReducer: WalletListReducer,
    MerchantListReducer: MerchantListReducer,
    TraditionalFactoryReducer: TraditionalFactoryReducer,
    BuyerPlatFormReducers: BuyerPlatFormReducers,
    InvoicesReducers: InvoicesReducers,
    PaymentReducers: PaymentReducers,
    PendingReducers: PendingReducers,
    PurchaseOrdersReducers: PurchaseOrdersReducers,
    TraditionalRequestReducers: TraditionalRequestReducers,
    NotificationReducer: NotificationReducer,
    EmailTemplateReducer: EmailTemplateReducer,
    CmsReducer: CmsReducer,
    ClientMonitorReducer: ClientMonitorReducer,
    FilterReducer: FilterReducer,
    RecoveriesReducers: RecoveriesReducers,
    DocumentMonitoringReducers: DocumentMonitoringReducers,
    PermissionReducers: PermissionReducers
});

export default rootReducers;
